import { History } from 'history';

import { getItem } from 'services/sessionStorage';

import BOUNDARIES from 'assets/savills-boundaries.json';
import { MILES_IN_METERS } from 'globalConstants';
import { RootState } from 'store/rootReducer';
import {
  initialState as initialSearchQuery,
  SearchQueryState,
} from 'store/reducers/searchQueryReducer';
import { initialState as quickSearch } from 'store/reducers/quickSearchReducer';
import { detailsInitialState as details } from 'store/reducers/DetailsReducer/initialState';
import { initialState as propertyInterests } from 'store/reducers/propertyInterestsReducer';
import { initialState as modal } from 'store/reducers/globalModalReducer';
import { initialState as documentUploader } from 'store/reducers/documentUploaderReducer';
import { initialState as documentPanel } from 'store/reducers/documentPanelReducer';
import { initialState as notes } from 'store/reducers/notesReducer';
import { initialState as formBuilder } from 'store/reducers/formBuilderReducer';
import { initialState as newRecord } from 'store/reducers/newRecordReducer';
import { initialState as companySelect } from 'store/reducers/companySelectReducer';
import { initialState as maps } from 'store/reducers/mapsReducer';
import { initialState as searchResults } from 'store/reducers/searchReducer';
import { initialState as searchStatistics } from 'store/reducers/searchStatisticsReducer';
import { initialState as filters } from 'store/reducers/filtersReducer';
import { initialState as pollingUpdate } from 'store/reducers/pollingUpdateReducer';
import { initialState as pollingNewRecord } from 'store/reducers/pollingNewRecordReducer';
import { initialState as epcSelect } from 'store/reducers/epcSelectReducer';
import { User } from 'services/auth';
import {
  MapPolygon,
  MapCorridor,
  MapPolygonWithBoundary,
} from 'components/GoogleMap/types';
import { initialState as tableConfig } from 'store/reducers/tableConfigReducer';
import { createObjFromURLParams } from 'utils/url';
import { FeatureFlagState } from 'feature_flags/types';
import { BoundariesType } from 'pages/QueryBuilder/types';
import { initialState as featureFlags } from 'store/reducers/featureFlagReducer';
import { initialState as portfolioNameSelect } from 'store/reducers/portfolioNameSelectReducer';
import { initialState as exportSearchResults } from 'store/reducers/exportSearchResultsReducer';
import { initialState as userQueries } from 'store/reducers/userQueriesReducer';
import { initialState as userProfile } from 'store/reducers/userProfileReducer';
import { initialState as telemetryStatistics } from 'store/reducers/telemetryStatisticsReducer';
import { initialState as usersList } from 'store/reducers/usersListReducer';
import { initialState as debts } from 'store/reducers/debtsReducer';
import { initialState as valuations } from 'store/reducers/valuationsReducer';
import { initialState as relatedParty } from 'store/reducers/relatedPartyReducer';
import { initialState as athenaUser } from 'store/reducers/athenaUserReducer';
import { initialState as pollingAddEpc } from 'store/reducers/pollingAddEpcReducer';
import { initialState as companyGetDeletionData } from './reducers/companyGetDeletionDataReducer';
import { initialState as scheduleTeamQuery } from './reducers/scheduleTeamQueryReducer';
import { initialState as fullScreenImage } from './reducers/fullScreenImageReducer';
import { initialState as primaryImage } from './reducers/primaryImageReducer';

const SEARCH_ROUTES = [
  '/search/primary',
  '/search/map',
  '/search/filters',
  '/search/results',
];

export const loadInitialSearchQueryContext = (history: History) => {
  const { location } = history;
  let searchParams: URLSearchParams | null = null;
  let searchContext = {
    ...initialSearchQuery,
  };

  if (location.pathname === '/login') {
    const destination = getItem('loginDestination');
    const parts = destination?.split('?');

    if (parts && parts[1]) {
      searchParams = new URLSearchParams(parts[1]);
    }
  }

  if (SEARCH_ROUTES.includes(location.pathname)) {
    searchParams = new URLSearchParams(location.search);
  }

  if (searchParams) {
    searchContext = {
      ...searchContext,
      ...createObjFromURLParams(searchParams),
    };
  }

  return searchContext;
};

const destructureCoordString = (path: string[]) => {
  return path.map((coordPair) => {
    const coords = coordPair.split('|').map((coord) => parseFloat(coord));
    return {
      lat: coords[0],
      lng: coords[1],
    };
  });
};

const circleInitialState = (locationRadius: string, location: string[]) => {
  const radius = parseFloat(locationRadius) * MILES_IN_METERS;
  const center = {
    latitude: parseFloat(location[0]),
    longitude: parseFloat(location[1]),
  };

  return { center, radius };
};

const polygonInitialState = (locationPath: string[], location: string[]) => {
  const center = {
    lat: parseFloat(location[0]),
    lng: parseFloat(location[1]),
  };
  const path = destructureCoordString(locationPath);

  return {
    center,
    path,
  };
};

const corridorInitialState = (
  locationPath: string[],
  location: string[],
  locationPolyline: string[],
  locationBuffer: number,
) => {
  const center = {
    lat: parseFloat(location[0]),
    lng: parseFloat(location[1]),
  };
  const path = destructureCoordString(locationPath);
  const polyline = destructureCoordString(locationPolyline);

  return {
    center,
    path,
    polyline,
    distance: locationBuffer,
  };
};

export const boundaryInitialState = (boundaryId: string) => {
  const boundary = BOUNDARIES.find((o) => boundaryId === o.link);

  return boundary
    ? {
        id: boundary.link,
        label: boundary.label,
        type: boundary.type,
      }
    : undefined;
};

export const loadMapsInitialState = (
  searchQuery: SearchQueryState,
  boundaryPolygon?: MapPolygonWithBoundary,
) => {
  const {
    locationPolyline,
    locationRadius,
    location,
    locationPath,
    locationBuffer,
    boundaryId,
  } = searchQuery;
  const mapState = { ...maps };

  if (boundaryId && location && boundaryPolygon) {
    const selectedBoundary = boundaryInitialState(boundaryId) as BoundariesType;

    mapState.activeShapes = { boundaries: boundaryPolygon.paths };

    if (selectedBoundary) mapState.selectedBoundary = selectedBoundary;

    return mapState;
  }

  if (locationRadius && location) {
    const circle = circleInitialState(locationRadius, location as string[]);
    mapState.activeShapes = {
      circle,
    };
    return mapState;
  }

  if (locationPolyline && locationPath && locationBuffer && location) {
    const corridor = corridorInitialState(
      locationPath,
      location as string[],
      locationPolyline,
      locationBuffer,
    ) as MapCorridor;

    mapState.activeShapes = {
      corridor,
    };

    return mapState;
  }

  if (locationPath && location) {
    const polygon = (polygonInitialState(
      locationPath,
      location as string[],
    ) as unknown) as MapPolygon;
    mapState.activeShapes = {
      polygon,
    };
    return mapState;
  }

  return mapState;
};

export type ApiStateResponseBody = {
  user: User;
  features:
    | Required<FeatureFlagState>['features']
    | Required<Pick<FeatureFlagState, 'error'>>;
};

export type ApiState = {
  user: User;
  features: FeatureFlagState;
};

const getPreloadedState = (
  history: History,
  apiState?: ApiState,
  boundaryPolygon?: MapPolygonWithBoundary,
) => {
  const searchQuery = (history && loadInitialSearchQueryContext(history)) || {};
  const mapsInitialState = loadMapsInitialState(searchQuery, boundaryPolygon);

  const state: RootState = {
    global: {
      account: {},
    },
    authentication: {
      user: apiState?.user,
      isLoggedIn: !!apiState?.user,
    },
    searchQuery,
    quickSearch,
    details,
    documentUploader,
    documentPanel,
    formBuilder,
    modal,
    newRecord,
    companySelect,
    portfolioNameSelect,
    maps: mapsInitialState,
    searchResults,
    searchStatistics,
    filters,
    propertyInterests,
    tableConfig,
    featureFlags: apiState?.features || featureFlags,
    notes,
    debts,
    pollingUpdate,
    pollingNewRecord,
    exportSearchResults,
    userQueries,
    userProfile,
    telemetryStatistics,
    usersList,
    valuations,
    relatedParty,
    athenaUser,
    companyGetDeletionData,
    scheduleTeamQuery,
    epcSelect,
    fullScreenImage,
    primaryImage,
    pollingAddEpc,
  };

  return state;
};

export default getPreloadedState;

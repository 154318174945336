/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action } from 'redux';
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import {
  switchMap,
  map,
  withLatestFrom,
  catchError,
  retryWhen,
  takeWhile,
} from 'rxjs/operators';

import { endpoints, Vertical } from 'globalConstants';
import { AuthenticatedRequestObservable } from 'apis/request';
import { DetailsActionTypes } from 'pages/Details/types';
import { getActivePollingUpdateEntity } from 'store/selectors/pollingUpdateSelector';
import {
  PollingUpdateActionTypes,
  pollingUpdateSuccess,
  pollingUpdateFail,
  PollingSuccessMetaType,
  pollingUpdateUnknown,
} from 'store/actions/pollingUpdateActions';
import { SearchActionTypes } from 'store/actions/searchActions';
import { EditRecordActionTypes } from 'store/actions/editRecordActions';
import {
  getEntityIdAndVerticalSelector,
  getSalePageData,
} from 'store/selectors/detailsSelectors';

type PollingUpdateEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const getUrl = (
  type: DetailsActionTypes | EditRecordActionTypes,
  verticalEndpoint: Vertical,
  resourceID: string,
  updateEntity: { vertical: Vertical; id: string; entity: any },
) => {
  const { id, vertical, entity } = updateEntity;
  return [
    DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_SUCCESS,
    EditRecordActionTypes.EDIT_RECORD_API_SUCCESS,
  ].includes(type)
    ? endpoints.vertical.byId(verticalEndpoint, resourceID)
    : endpoints.updateHistory(id, vertical, entity);
};

const getMetaType = (type: DetailsActionTypes) => {
  if (type === DetailsActionTypes.UPDATE_DETAILS_API_SUCCESS) {
    return PollingSuccessMetaType.Details;
  }
  return PollingSuccessMetaType.Results;
};

const pollingUpdateEpic: Epic = (
  action$,
  state$,
  dependencies: PollingUpdateEpicDependencies,
) => {
  return action$.pipe(
    ofType<Action, Action, any>(
      DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_SUCCESS,
      DetailsActionTypes.UPDATE_DETAILS_API_SUCCESS,
      EditRecordActionTypes.EDIT_RECORD_API_SUCCESS,
      SearchActionTypes.FETCH_SEARCH_RESULTS_SILENTLY,
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const {
        time,
        entity: vertical,
        id,
        property: entity,
      } = getActivePollingUpdateEntity(state);

      const resource = getEntityIdAndVerticalSelector(state$.value);
      const verticalEndpoint = resource.vertical!!;
      const { resourceID } = resource;
      const isSuccessType =
        action.type ===
          DetailsActionTypes.DELETE_BUILDING_FROM_PORTFOLIO_API_SUCCESS ||
        action.type === EditRecordActionTypes.EDIT_RECORD_API_SUCCESS;

      const url = getUrl(action.type, verticalEndpoint, resourceID, {
        vertical: vertical!,
        id: id!,
        entity,
      });

      let retryAttempts = 0;
      const currentDetails = JSON.stringify(getSalePageData(state));
      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url,
        })()
        .pipe(
          map((results) => {
            if (
              isSuccessType &&
              currentDetails === JSON.stringify(results.response)
            ) {
              throw new Error(PollingUpdateActionTypes.POLLING_RETRY);
            }

            if (isSuccessType) {
              retryAttempts = 0;
              return pollingUpdateSuccess(PollingSuccessMetaType.Details);
            }

            const mostRecentUpdate = new Date(
              results.response.history.mostTrusted.updatedAt,
            ).getTime();

            const moreRecentUpdateExists = time && mostRecentUpdate < time;
            if (moreRecentUpdateExists) {
              throw new Error(PollingUpdateActionTypes.POLLING_RETRY);
            }

            const meta = getMetaType(action.type);
            retryAttempts = 0;
            return pollingUpdateSuccess(meta);
          }),
          retryWhen((errors) =>
            errors.pipe(
              takeWhile((error) => {
                if (
                  error.message !== PollingUpdateActionTypes.POLLING_RETRY ||
                  retryAttempts === 2
                ) {
                  throw error;
                }

                retryAttempts += 1;
                return true;
              }),
            ),
          ),
          catchError((error) => {
            retryAttempts = 0;

            return error.message === PollingUpdateActionTypes.POLLING_RETRY
              ? of(pollingUpdateUnknown())
              : of(pollingUpdateFail());
          }),
        );
    }),
  );
};

export default pollingUpdateEpic;

import { Reducer } from 'redux';
import {
  PollingAddEpcActions,
  PollingAddEpcActionTypes,
} from 'store/actions/pollingAddEpcActions';
import {
  AddEpcActions,
  AddEpcActionTypes,
} from 'connected/EpcCertificatesPanel/AddEpc/types';

interface PollingAddEpcState {
  polling: boolean;
  epcIds: string[];
  buildingId: string;
  success: 'success' | 'undetermined' | 'fail' | 'null';
}

export const initialState: PollingAddEpcState = {
  polling: false,
  epcIds: [],
  success: 'null',
  buildingId: '',
};

type Actions = PollingAddEpcActions | AddEpcActions;

const pollingAddEpcReducer: Reducer<PollingAddEpcState, Actions> = (
  state: PollingAddEpcState = initialState,
  action,
): PollingAddEpcState => {
  switch (action.type) {
    case AddEpcActionTypes.ADD_EPC_SUCCESS: {
      return {
        ...state,
        polling: true,
        epcIds: action.payload.epcIds,
        buildingId: action.payload.buildingId,
      };
    }

    case PollingAddEpcActionTypes.POLLING_ADD_EPC_SUCCESS: {
      return {
        ...state,
        polling: false,
        success: 'success',
      };
    }

    case PollingAddEpcActionTypes.POLLING_ADD_EPC_UNKNOWN: {
      return {
        ...state,
        polling: false,
        success: 'undetermined',
      };
    }

    case PollingAddEpcActionTypes.POLLING_ADD_EPC_FAIL: {
      return {
        ...state,
        polling: false,
        success: 'fail',
      };
    }

    default:
      return state;
  }
};

export default pollingAddEpcReducer;

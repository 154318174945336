import { TableRowProps } from 'components/Table/types';
import { formatArea } from 'services/format';
import { TABLE_CELL_TYPE } from 'components/Table/constants';
import { mapEpcRatingCell } from 'components/Table/EpcRatingCell';
import { format } from 'date-fns';
import { EPC_SELECT_TABLE_COPY } from './constants';
import { EpcSelectAthenaResult } from '../types';

export const mapAthenaResultsToTableFormattedData = (
  record: EpcSelectAthenaResult,
  selectedRecordsIds: string[],
  onCheckboxChange: (id: string, checked: boolean) => void,
): TableRowProps => ({
  view: {
    value: record.id,
    subValue: selectedRecordsIds.includes(record.id),
    cellType: TABLE_CELL_TYPE.CHECKBOX,
    onChange: (value: any) => onCheckboxChange(record.id, value),
  },
  address: {
    value: record.address,
  },
  type: {
    value:
      record.type === 'display'
        ? EPC_SELECT_TABLE_COPY.display
        : EPC_SELECT_TABLE_COPY.nonDomestic,
  },
  date: {
    value: format(new Date(record.assessmentDate), 'dd/MM/yyyy'),
  },
  expiryDate: {
    value: format(new Date(record.expiryDate), 'dd/MM/yyyy'),
  },
  floorArea: {
    value: record.floorArea
      ? formatArea(Math.round(Number(record.floorArea)))
      : null,
  },
  rating: {
    value: mapEpcRatingCell(Number(record.ratingNumber), record.ratingBand),
  },
});

import { FEATURE_FLAGS } from 'feature_flags/types';
import { urls } from 'globalConstants';
import { MenuItemData } from './types';

export const menuItems: MenuItemData[] = [
  {
    title: 'Search',
    href: urls.search,
    key: 'search',
    tabIndex: 1,
  },
  {
    title: 'New Sale Activity',
    key: 'new-sale',
    href: '',
    tabIndex: 2,
    children: [
      {
        title: 'Single Asset',
        href: urls.newSale.single,
        key: 'single-sale',
        tabIndex: 3,
      },
      {
        title: 'Portfolio',
        href: urls.newSale.portfolio,
        key: 'portfolio-sale',
        tabIndex: 4,
        featureFlag: FEATURE_FLAGS.PORTFOLIO_SALES,
      },
    ],
  },
  {
    title: 'New Lease Activity',
    key: 'new-lease',
    href: urls.newLease.single,
    tabIndex: 5,
  },
  {
    title: 'Admin Portal',
    admin: true,
    href: urls.admin,
    key: 'admin-portal',
    tabIndex: 6,
    children: [
      {
        title: 'Admin Homepage',
        href: urls.admin,
        key: 'adminHomepage',
        tabIndex: 7,
      },
      {
        title: 'Athena Contribution',
        href: urls.analytics.athenaContribution.page,
        key: 'athenaContribution',
        tabIndex: 8,
      },
      {
        title: 'Athena Usage',
        href: urls.analytics.teamLeaderboard.page,
        key: 'teamLeaderboard',
        tabIndex: 9,
      },
    ],
  },
  {
    title: 'Analytics',
    key: 'analytics',
    tabIndex: 10,
    href: '',
    featureFlag: FEATURE_FLAGS.ANALYTICS,
    children: [
      {
        title: 'EPC Analytics',
        href: urls.analytics.epcAnalytics.page,
        key: 'epcAnalytics',
        tabIndex: 11,
      },
      {
        title: 'Market Analytics',
        href: urls.analytics.marketAnalytics.page,
        key: 'marketAnalytics',
        tabIndex: 12,
      },
      {
        title: 'London Office Development Pipeline',
        href: urls.analytics.londonOfficeDevelopmentPipeline.page,
        key: 'londonOfficeDevelopmentPipeline',
        tabIndex: 13,
      },
    ],
  },
];

export enum PollingAddEpcActionTypes {
  POLLING_ADD_EPC_SUCCESS = 'POLLING_ADD_EPC_SUCCESS',
  POLLING_ADD_EPC_UNKNOWN = 'POLLING_ADD_EPC_UNKNOWN',
  POLLING_ADD_EPC_FAIL = 'POLLING_ADD_EPC_API_FAIL',
  POLLING_RETRY = 'POLLING_RETRY',
  POLLING_ADD_EPC_COMPLETE = 'POLLING_ADD_EPC_COMPLETE',
}

export type PollingAddEpcSuccessAction = {
  type: PollingAddEpcActionTypes.POLLING_ADD_EPC_SUCCESS;
};

export const pollingAddEpcSuccess = (): PollingAddEpcSuccessAction => ({
  type: PollingAddEpcActionTypes.POLLING_ADD_EPC_SUCCESS,
});

export type PollingAddEpcFailAction = {
  type: PollingAddEpcActionTypes.POLLING_ADD_EPC_FAIL;
};

export const pollingAddEpcFail = (): PollingAddEpcFailAction => ({
  type: PollingAddEpcActionTypes.POLLING_ADD_EPC_FAIL,
});

export type PollingAddEpcUnknownAction = {
  type: PollingAddEpcActionTypes.POLLING_ADD_EPC_UNKNOWN;
};

export const pollingAddEpcUnknown = (): PollingAddEpcUnknownAction => ({
  type: PollingAddEpcActionTypes.POLLING_ADD_EPC_UNKNOWN,
});

export type PollingAddEpcCompleteAction = {
  type: PollingAddEpcActionTypes.POLLING_ADD_EPC_COMPLETE;
};

export const pollingAddEpcComplete = (): PollingAddEpcCompleteAction => ({
  type: PollingAddEpcActionTypes.POLLING_ADD_EPC_COMPLETE,
});

export type PollingAddEpcActions =
  | PollingAddEpcSuccessAction
  | PollingAddEpcFailAction
  | PollingAddEpcUnknownAction
  | PollingAddEpcCompleteAction;

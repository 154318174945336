import { GlobalApiFailError } from './types';

export enum PrimaryImageActionTypes {
  SET_PRIMARY_IMAGE = 'SET_PRIMARY_IMAGE',
  SET_PRIMARY_IMAGE_SUCCESS = 'SET_PRIMARY_IMAGE_SUCCESS',
  SET_PRIMARY_IMAGE_FAIL = 'SET_PRIMARY_IMAGE_FAIL',
}

export type ImagePayloadType = {
  canonicalId: string;
  filename: string;
};

export type SetPrimaryImageAction = {
  type: PrimaryImageActionTypes.SET_PRIMARY_IMAGE;
  payload: ImagePayloadType;
};

export type SetPrimaryImageSuccessAction = {
  type: PrimaryImageActionTypes.SET_PRIMARY_IMAGE_SUCCESS;
};

export type SetPrimaryImageFailAction = {
  type: PrimaryImageActionTypes.SET_PRIMARY_IMAGE_FAIL;
  payload: GlobalApiFailError;
};

export const setPrimaryImage = (
  payload: ImagePayloadType,
): SetPrimaryImageAction => ({
  type: PrimaryImageActionTypes.SET_PRIMARY_IMAGE,
  payload,
});

export const setPrimaryImageSuccess = (): SetPrimaryImageSuccessAction => ({
  type: PrimaryImageActionTypes.SET_PRIMARY_IMAGE_SUCCESS,
});

export const setPrimaryImageFail = (
  payload: GlobalApiFailError,
): SetPrimaryImageFailAction => ({
  type: PrimaryImageActionTypes.SET_PRIMARY_IMAGE_FAIL,
  payload,
});

export type PrimaryImageActions =
  | SetPrimaryImageAction
  | SetPrimaryImageSuccessAction
  | SetPrimaryImageFailAction;

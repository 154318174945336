/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action } from 'redux';
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import {
  switchMap,
  map,
  withLatestFrom,
  catchError,
  retryWhen,
  takeWhile,
  delay,
} from 'rxjs/operators';

import { endpoints } from 'globalConstants';
import { AuthenticatedRequestObservable } from 'apis/request';
import { AddEpcActionTypes } from 'connected/EpcCertificatesPanel/AddEpc/types';
import {
  getPollingAddEpcBuildingId,
  getPollingAddEpcLmkKeys,
} from 'store/selectors/pollingAddEpc';
import {
  PollingAddEpcActionTypes,
  pollingAddEpcFail,
  pollingAddEpcSuccess,
  pollingAddEpcUnknown,
} from 'store/actions/pollingAddEpcActions';

type PollingUpdateEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

interface GetEpcDto {
  id: string;
  lmkKey: string;
}

const pollingAddEpc: Epic = (
  action$,
  state$,
  dependencies: PollingUpdateEpicDependencies,
) => {
  return action$.pipe(
    ofType<Action, Action, any>(AddEpcActionTypes.ADD_EPC_SUCCESS),
    withLatestFrom(state$),
    switchMap(([_, state]) => {
      const buildingId = getPollingAddEpcBuildingId(state);
      const epcIds = getPollingAddEpcLmkKeys(state);
      const url = `${endpoints.epc}?buildingId=${encodeURIComponent(
        buildingId,
      )}`;

      let retryAttempts = 0;

      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url,
        })()
        .pipe(
          map((results) => {
            const currentLmkKeys: string[] = results.response.map(
              (r: GetEpcDto) => r.lmkKey,
            );
            if (!epcIds.some((id) => currentLmkKeys.includes(id))) {
              throw new Error(PollingAddEpcActionTypes.POLLING_RETRY);
            }

            return pollingAddEpcSuccess();
          }),
          retryWhen((errors) =>
            errors.pipe(
              takeWhile((error) => {
                if (
                  error.message !== PollingAddEpcActionTypes.POLLING_RETRY ||
                  retryAttempts === 5
                ) {
                  throw error;
                }

                retryAttempts += 1;
                return true;
              }),
              delay(1000),
            ),
          ),
          catchError((error) => {
            retryAttempts = 0;

            return error.message === PollingAddEpcActionTypes.POLLING_RETRY
              ? of(pollingAddEpcUnknown())
              : of(pollingAddEpcFail());
          }),
        );
    }),
  );
};

export default pollingAddEpc;

import React from 'react';
import { SubMenuListItem, SubMenuLink } from './GlobalMenuStyled';
import { MenuItemData } from './types';

interface ChildMenuItemProps {
  child: MenuItemData;
  index: number;
  pathname: any;
  handleSelect: any;
}

const ChildMenuItem: React.FC<ChildMenuItemProps> = ({
  child,
  index,
  pathname,
  handleSelect,
}: ChildMenuItemProps) => {
  return (
    <SubMenuListItem key={child.key}>
      <SubMenuLink
        to={child.href}
        onKeyDown={(e) => handleSelect(e, index)}
        isActive={pathname.includes(child.href)}
        tabIndex={child.tabIndex}
      >
        {child.title}
      </SubMenuLink>
    </SubMenuListItem>
  );
};

export default ChildMenuItem;

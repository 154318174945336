import { RootState } from 'store/rootReducer';

export const getIsPollingAddEpc = (state: RootState) =>
  state.pollingAddEpc.polling;

export const getPollingAddEpcLmkKeys = (state: RootState) =>
  state.pollingAddEpc.epcIds;

export const getPollingAddEpcBuildingId = (state: RootState) =>
  state.pollingAddEpc.buildingId;

export const getPollingAddEpcStatus = (state: RootState) =>
  state.pollingAddEpc.success;
